import { Box, Link as MuiLink, Typography } from "@mui/material";
import { header_content } from "../../../../../data/general_texts";
import { FaAngleRight } from "react-icons/fa6";
import { useGoldPrice } from "../../../../../contexts/GoldPriceProvider";
import { moneyMask } from "../../../../../utils/functions";

export const PromotionBanner = ({ isMobile, isLogged }) => {

    const { goldPrice } = useGoldPrice();

    if (isMobile) {
        return (
            <Box sx={styles.promotion_mobile}>
                <MuiLink color="secondary" href={header_content.promotion_banner_content.cta_link} target="_blank" textAlign={'center'} sx={styles.cta} >
                    {header_content.promotion_banner_content.cta} <Box component={FaAngleRight} ml={'.5rem'} />
                </MuiLink>
            </Box>
        );
    } else {
        return (
            <Box sx={styles.promotion}>
                {
                    isLogged ?
                        <>
                            <Typography color="secondary" mr={'.5rem'}>
                                Cotação do ouro de hoje:
                            </Typography>
                            <Typography color="#76ff03" fontWeight={600}>
                                {moneyMask(goldPrice)}
                            </Typography>
                        </>
                        :
                        <>
                            <Typography color="white" mr={'.25rem'}>
                                {header_content.promotion_banner_content.text}
                            </Typography>
                            <MuiLink color="secondary" href={header_content.promotion_banner_content.cta_link} target="_blank" textAlign={'center'} sx={styles.cta} >
                                {header_content.promotion_banner_content.cta} <Box component={FaAngleRight} ml={'.5rem'} />
                            </MuiLink>
                        </>
                }
            </Box>
        );
    };
};

const styles = {
    promotion: {
        bgcolor: '#010101',
        display: {
            xs: 'none',
            md: 'flex'
        },
        justifyContent: 'center',
        alignItems: 'center',
        p: '.5rem',
    },
    promotion_mobile: {
        bgcolor: '#010101',
        display: {
            xs: 'flex',
            md: 'none'
        },
        justifyContent: 'center',
        alignItems: 'center',
        p: '.5rem',
    },
    cta: {
        display: 'flex',
        alignItems: 'center',
        fontSize: {
            xs: '.875rem',
            md: '1rem'
        }
    }
};

export default PromotionBanner;