import { useParams } from "react-router-dom";
import CustomContainer from "../../components/CustomContainer";
import Banner from "../Catalog/Components/Banner";
import ProductGrid from "../Catalog/Components/ProductGrid";

export default function CustomStoreCatalog() {

    const { customerId } = useParams();

    return (
        <>
            <Banner isCustomerStoreBanner={true} />
            <CustomContainer>
                <ProductGrid isCustomerStoreGrid  />
            </CustomContainer>
        </>
    );
};