import { useContext, useState } from "react";
import { createContext } from "react";
import { signInCustomer } from "../helpers/httpRequests/auth";
import appToast from "../components/appToast";

export const AuthContext = createContext({});

function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [userName, setUserName] = useState(null);
    const [id, setId] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    async function signIn(credentials) {

        // setIsLoading(true);

        // const response = await signInCustomer(credentials);

        // if (response) {

        //     if (response.error) {
        //         appToast.error(response.message);
        //     } else {
        //         setToken(response.token)
        //     };
        //     setIsLoading(false);

        // } else {
        //     appToast.error("Erro de conexão! Tente novamente mais tarde.");
        //     setIsLoading(false);
        // };
        appToast.success("Em desenvolvimento! Avisaremos quando disponível.")
    };

    function signOut() {
        setIsAuthenticated(false);
        setUser(null);
        setToken(null);
        setId(null);
        localStorage.removeItem('user_token');
    };

    const contextValues = {
        user,
        id,
        token,
        userName,
        signOut,
        signIn,
        isAuthenticated,
        isLoading
    };

    return (
        <AuthContext.Provider
            value={contextValues}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };