import { useContext } from "react";
import { createContext } from "react";
import { getGoldPrice } from "../helpers/httpRequests/gold_price";
import { useQuery } from "@tanstack/react-query";

export const GoldPriceContext = createContext({});

function GoldPriceProvider({ children }) {

    const { data, isLoading } = useQuery({ queryKey: ['getGoldPrice'], queryFn: getGoldPrice });

    const goldPrice = data?.success ? data.price : 'R$9.999,00';

    const contextValues = {
        goldPrice,
        isGoldPriceLoading: isLoading
    };

    return (
        <GoldPriceContext.Provider
            value={contextValues}>
            {children}
        </GoldPriceContext.Provider>
    );
};

const useGoldPrice = () => useContext(GoldPriceContext);

export { useGoldPrice, GoldPriceProvider };