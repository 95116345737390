import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import CustomStoreCatalog from './pages/CustomStoreCatalog';
import HomeUser from './pages/HomeUser';
import Login from './pages/Login';
import Home from './pages/Home';
import RouteWrapper from './components/RouteWrapper';
import Catalog from './pages/Catalog';
import ProductDetails from './pages/ProductDetails';
import CustomOrders from './pages/CustomOrder';
import Layout from './components/layout';

function App() {
  return (
    <Routes>
      {/* Rota fora do Layout */}
      <Route path="/catalogo-parceiro/:customerId" element={<CustomStoreCatalog />} />

      {/* Rotas com Layout */}
      <Route
        path="*"
        element={
          <Layout>
            <Routes>
              <Route path="/" element={<RouteWrapper defaultComponent={<Home />} loggedComponent={<HomeUser />} />} />
              <Route path="/login" element={<RouteWrapper defaultComponent={<Login />} loggedComponent={<HomeUser />} />} />
              <Route path="/home-usuario" element={<RouteWrapper isPrivate defaultComponent={<HomeUser />} loggedComponent={<Login />} />} />
              <Route path="/catalogo" element={<Catalog />} />
              <Route path="/produto-detalhes/:id" element={<ProductDetails />} />
              <Route path="/pedido-personalizado/:id?" element={<RouteWrapper isPrivate defaultComponent={<Login />} loggedComponent={<CustomOrders />} />} />
            </Routes>
          </Layout>
        }
      />
    </Routes>
  );
};

export default App;
