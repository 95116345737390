import { Box, Grid2 } from "@mui/material";
import CtaContent from "./Components/CtaContent";
import { LoginForm } from "./Components/LoginForm";
import CustomContainer from "../../components/CustomContainer";

const styles = {
    section: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'white',
        minHeight: '90vh'
    },
    gridSpacing: { xs: 10, md: 20 }
};

export default function Login() {

    return (
        <CustomContainer>
            <Box sx={styles.section}>
                <Grid2 container spacing={styles.gridSpacing}>
                    <CtaContent />
                    <LoginForm />
                </Grid2>
            </Box>
        </CustomContainer>
    );
};