import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";

import background from '../../../../assets/hero_bg.jpg';
import { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";

export default function Banner({ title, isCustomerStoreBanner, customerData }) {

    const [businessImage, setBusinessImage] = useState('https://placehold.co/200x200');
    const [businessName, setBusinessName] = useState('');
    const [whatsapp, setWhatsapp] = useState('');

    const theme = useTheme();

    useEffect(() => {

        if (isCustomerStoreBanner) {
            setBusinessName(customerData?.business_name);
            setWhatsapp(customerData?.business_whatsapp);
            setBusinessImage(customerData?.business_image);
        };

    }, [isCustomerStoreBanner]);

    return (
        <Box id="banner" sx={{
            width: '100vw',
            background: `url(${background})`,
            backgroundSize: '100%'
        }}>
            <Box sx={{
                py: '5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                {
                    isCustomerStoreBanner ?
                        <>
                            <Avatar src={`${businessImage}`} sx={{ width: 80, height: 80 }}/>
                            <Typography variant="h1" sx={{
                                my: '2rem'
                            }}>
                                Catálogo da joalheria {businessName}
                            </Typography>
                            <Typography color="white" sx={{
                                mb: '2rem'
                            }}>
                                Para fazer pedidos ou tirar dúvidas, entre em contato com {businessName}.
                            </Typography>
                            <IconButton target="_blank" href={`https://wa.me/55${whatsapp}>?Olá! Tudo bem? Estou vindo do portal IG e gostaria tirar uma dúvida!`} sx={{ border: `3px solid ${theme.palette.secondary.main}` }}>
                                <FaWhatsapp color="white" size={30} />
                            </IconButton>
                        </>
                        :
                        <Typography variant="h1">
                            {title}
                        </Typography>
                }
            </Box>
        </Box>
    );
};