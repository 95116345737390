import { Box, Button, List, ListItem, ListItemButton, Typography } from "@mui/material";
import CustomContainer from "../../components/CustomContainer";
import { FaAngleRight, FaArrowRightFromBracket } from "react-icons/fa6";
import { useAuth } from "../../contexts/AuthProvider";
import { Link } from "react-router-dom";

export default function HomeUser() {
    const { signOut } = useAuth();

    const menuItems = [
        { title: "Seu Catálogo", path: "/catalogo", disabled: true },
        { title: "Pedidos", path: "/pedidos", disabled: true },
        { title: "Customizados e Reformas", path: "/customizados-e-reformas", disabled: true },
        { title: "Histórico de pedidos", path: "/historico-de-pedidos", disabled: true },
        { title: "Dados Pessoais", path: "/dados-pessoais", disabled: true },
        { title: "Alterar senha", path: "/alterar-senha", disabled: true },
    ];

    return (
        <CustomContainer>
            <Box sx={styles.section}>
                <Box sx={styles.sidebar}>
                    <Typography variant="h2">Minha Conta</Typography>
                    <MenuList menuItems={menuItems} onSignOut={signOut} />
                </Box>
                <Box sx={styles.contentBox}>
                    <Typography variant="h2">Acesse seu Catálogo de Parceiro</Typography>
                    <Typography textAlign={'left'} my={'1.5rem'}>
                        Compartilhe com todos os seus clientes seu catálogo personalizado de Parceiro Inovart Gold!
                    </Typography>
                    <Box>
                        <Button disabled variant="contained" color="secondary">
                            Compartilhar catálogo
                        </Button>
                    </Box>
                </Box>
            </Box>
        </CustomContainer>
    );
}

function MenuList({ menuItems, onSignOut }) {
    return (
        <List sx={styles.list}>
            {menuItems.map((link, index) => (
                <MenuItem key={index} {...link} />
            ))}
            <SignOutItem onSignOut={onSignOut} />
        </List>
    );
}

function MenuItem({ title, path, disabled }) {
    return (
        <ListItem disablePadding sx={styles.listItem}>
            <ListItemButton component={Link} to={path} disabled={disabled} sx={styles.listItemButton}>
                <Typography my={'.5rem'}>{title}</Typography>
                <FaAngleRight />
            </ListItemButton>
        </ListItem>
    );
}

function SignOutItem({ onSignOut }) {
    return (
        <ListItem disablePadding sx={styles.listItem}>
            <ListItemButton onClick={onSignOut} sx={styles.listItemButton}>
                <Typography my={'.5rem'}>Sair</Typography>
                <FaArrowRightFromBracket />
            </ListItemButton>
        </ListItem>
    );
}

const styles = {
    section: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        minHeight: '90vh',
        py: '5rem',
    },
    sidebar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '30%',
        mr: '5rem',
    },
    list: {
        width: '100%',
    },
    listItem: {
        textAlign: 'left',
        color: 'black',
    },
    listItemButton: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    contentBox: {
        border: '2px solid black',
        p: '2.5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '70%',
    },
};