import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "../../theme/customerTheme";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "../../contexts/AuthProvider";
import { GoldPriceProvider } from "../../contexts/GoldPriceProvider";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

export default function ComponentProvider({ children }) {

    const queryClient = new QueryClient();

    return (
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <AuthProvider>
                        <GoldPriceProvider>
                            {children}
                        </GoldPriceProvider>
                    </AuthProvider>
                </BrowserRouter>
                <Toaster
                    position='top-center'
                    reverseOrder={false}
                    duration={3000}
                />
                <CssBaseline />
            </QueryClientProvider>
        </ThemeProvider>
    );
};