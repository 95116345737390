import { Typography } from "@mui/material";
import toast from "react-hot-toast";

export const moneyMask = (value) => {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(value) / 100
    );

    return 'R$ ' + result
};

export const moneyFormat = (value) => {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(value) / 100
    );

    return result
};

export const copyToClipBoard = async (text, title = 'Texto') => {

    if (text) {

        await navigator.clipboard.writeText(text);

        toast.success(<Typography textAlign={'left'}>{(title)} copiado para sua área de transferência!</Typography>)
    };

};

export const clearNonNumericCharacters = (char) => {

    return char.replace(/\D/g, '');
};


export const hasImage = (img) => {

    return img
        ? img
        : "https://inovartgold.com.br/assets/logo/logo.jpg";
};