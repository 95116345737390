import { Typography } from "@mui/material";
import toast from "react-hot-toast";

const appToast = {};

appToast.error = (message) => {

    const defErrorMessage = "Houve um erro ao tentar realizar esta ação! Entre em contato com o administrador."

    toast.error(
        <Typography textAlign={'left'}>
            {message ? message : defErrorMessage}
        </Typography>
    );
};

appToast.success = (message) => {

    const defSuccessMessage = "Ação realizada com sucesso!"

    toast.success(
        <Typography textAlign={'left'}>
            {message ? message : defSuccessMessage}
        </Typography>
    );
};

export default appToast;