import { Box, Button, Grid2, Typography } from "@mui/material";
import { FaMagnifyingGlass, FaWhatsapp } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function ProductItem({
    isCustomerStoreGrid,
    name,
    image_url,
    material,
    price,
    weight,
    id,
}) {

    return (
        <Grid2 size={styles.gridSize}>
            <Box sx={styles.container}>
                <Box
                    component="img"
                    src={image_url}
                    alt={name}
                    sx={styles.image}
                />
                <Box sx={styles.content}>
                    <Typography sx={styles.name}>{name}</Typography>
                    {
                        !isCustomerStoreGrid && (
                            <>
                                <Typography sx={styles.price}>R${price}</Typography>
                                <Typography sx={styles.weight}>
                                    Peso ideal: {weight}g
                                </Typography>
                            </>
                        )
                    }
                    <Button
                        component={Link}
                        to={`/produto-detalhes/${id}`}
                        sx={styles.button}
                        variant={isCustomerStoreGrid ? 'outlined' : 'contained'}
                        color={isCustomerStoreGrid ? 'primary' : 'secondary'}
                    >
                        Ver detalhes
                    </Button>
                    {
                        isCustomerStoreGrid && (
                            <Button
                                startIcon={<FaWhatsapp />}
                                component={Link}
                                to={`/produto-detalhes/${id}`}
                                sx={styles.button}
                                variant={isCustomerStoreGrid ? 'contained' : 'outlined'}
                                color={isCustomerStoreGrid ? 'secondary' : 'primary'}
                            >
                                Tenho interesse
                            </Button>
                        )
                    }
                </Box>
            </Box>
        </Grid2>
    );
};

const styles = {
    gridSize: {
        xs: 12,
        sm: 4,
        md: 3,
    },
    container: {
        border: "2px solid #e4e4e4",
    },
    image: {
        width: "100%",
        height: "10rem",
    },
    content: {
        padding: "1rem 0.5rem",
    },
    name: {
        marginBottom: "1rem",
        textAlign: "left",
    },
    price: {
        fontWeight: 600,
        textAlign: "left",
    },
    weight: {
        marginTop: "0.5rem",
        textAlign: "left",
    },
    button: {
        marginTop: "1rem",
    },
};